<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="전략마케팅 처리현황">
      - 마케팅 담당자와 각 팀 BL님들은 아래 내용에 적합한 팀을 배정해 주시기 바랍니다.<br />
      - 진행상태 또한 마케팅 담당자, 각 팀 BL님들이 상황에 따라 체크해 주시기 바랍니다.<br />
      - 고객사DB와 연결된 경우, 회사보기 버튼과 일지작성 버튼이 노출됩니다.<br />
      - 담당자 이메일주소가 명함DB에 존재하지 않는 경우 명함보내기 버튼이 노출됩니다.<br />
      - 담당자 이메일주소가 명함DB에 존재하는 경우 명함보기 버튼이 노출됩니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row">
            <colgroup>
              <col width="180" />
              <col width="*" />
              <col width="180" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th>진행상태</th>
                <td>
                  <select @change="view.doUpdate('state')" v-model="view.info.state" class="w-200px">
                    <option value="">진행상황 전체</option>
                    <option value="REQUEST">대기중</option>
                    <option value="PROCESS">처리중</option>
                    <option value="OPEN">OPEN</option>
                    <option value="FAIL">FAIL</option>
                    <option value="STOP">중단</option>
                  </select>
                </td>
                <th>본부/팀 배정</th>
                <td>
                  <carrot-dept v-if="view.is_loaded" @change="view.doUpdate('office')" v-model="view.info.idx_office" class="w-180px"></carrot-dept>
                  <carrot-team v-if="view.is_loaded" @change="view.doUpdate('team')" :idx_office="view.info.idx_office" v-model="view.info.idx_team" class="w-180px ml-5"></carrot-team>
                </td>
              </tr>
              <!-- <tr>
                                <th>분류</th>
                                <td colspan="3">
                                    {{ view.info.category_txt }}
                                </td>
                            </tr> -->
              <tr>
                <th>작성자</th>
                <td v-if="view.info.ename || view.info.kname">{{ view.info.ename }}({{ view.info.kname }})</td>
                <td v-else>직접문의</td>
                <th>등록시간</th>
                <td>
                  {{ view.info.createdate }}
                </td>
              </tr>
              <tr>
                <th>회사명</th>
                <td>
                  <p class="float-left">{{ view.info.companyname }}</p>
                  <button v-if="view.info.idx_company > 0" @click="view.goCustomerDBView(view.info.idx_company)" class="btn-default float-left ml-10">회사보기</button>
                </td>
                <th>담당자</th>
                <td>
                  <p class="float-left">{{ view.info.manager }}</p>
                  <button v-if="view.info.idx_bizcard && view.info.idx_bizcard < 1" @click="view.goBizCardAdd" class="btn-default float-left ml-10">명함보내기</button>
                  <button v-if="view.info.idx_bizcard && view.info.idx_bizcard > 0" @click="view.goBizCardView(view.info.idx_bizcard)" class="btn-default float-left ml-10">명함보기</button>
                </td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>
                  {{ view.info.phone }}
                </td>
                <th>E-mail</th>
                <td>
                  {{ view.info.email }}
                </td>
              </tr>
              <tr>
                <th>유입경로<br />(캐럿 매니저가 직접 입력 시<br />유입 경로 파악 부탁드립니다.)</th>
                <td v-if="view.info.enterPathText">기타 : {{ view.info.enterPathText }}</td>
                <td v-else>
                  {{ view.info.enterPath }}
                </td>
                <th>유입사이트</th>
                <td>{{ view.info.enterUrl }}</td>
              </tr>
              <tr>
                <th>과정종류</th>
                <td colspan="3">{{ view.info.program }}</td>
              </tr>
              <tr>
                <th>주소</th>
                <td colspan="3">{{ view.info.zipcode ? "(" + view.info.zipcode + ")" : "" }} {{ view.info.addr }} {{ view.info.addr_sub }}</td>
              </tr>
              <tr>
                <th>
                  내용<br>
                  <button v-if="view.info.idx_company > 0" @click="view.goScheduleOutsideWorkAdd(view.info.idx_company)" class="btn-default th-wrap">일지작성</button>
                </th>
                <td colspan="3" v-html="view.info.contents"></td>
              </tr>
            </tbody>
          </table>
          <button @click="view.doCancel" class="btn-default float-left mt-30">목록</button>
          <!-- <button v-if="view.info.is_del_able == 'Y'" @click="view.doDelete" class="btn-default float-left mt-30 ml-10">삭제</button> -->
          <button v-if="view.is_del" @click="view.doDelete" class="btn-default float-left mt-30 ml-10">삭제</button>
          
          <router-link :to="{ name: 'customerManagement-statusSMPMod-idx', params: { idx: view.idx } }"><button class="btn-default float-right mt-30">수정</button></router-link>
          <div class="clear"></div>

          <div class="mt-40 comment-list">
            <div class="title-box">댓글달기</div>
            <div class="add-box">
              <textarea placeholder="댓글을 남겨주세요." v-model="cmt.newtext"></textarea>
              <button class="btn" @click="cmt.addCmt(0)">등록</button>
            </div>

            <div class="list-box">
              <!-- 샘플 -->
              <div class="reply" v-for="(irow, i) in cmt.list" :key="i" :class="cmt.bindDetph(irow.depth)">
                <div class="list">
                  <div class="photobox">
                    <img class="my-profile" :src="irow.photoimg" :alt="irow.ename + '\'s profile'" />
                  </div>
                  <div class="textbox">
                    <div class="nameDay">
                      <span class="name" v-if="irow.kname">{{ irow.ename }}({{ irow.kname }})</span>
                      <span class="day">{{ irow.wdate }}</span>
                    </div>
                    <div class="text preline">{{ irow.content }}</div>
                    <div class="text" v-if="irow.is_mode == 'A'">
                      <div class="add-box mt-10">
                        <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext"></textarea>
                        <button class="btn type1" @click="cmt.addCmt(irow.idx)">등록</button>
                      </div>
                    </div>
                    <div class="text" v-if="irow.is_mode == 'M'">
                      <div class="add-box mt-10">
                        <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext"></textarea>
                        <button class="btn type1" @click="cmt.modCmt(irow.idx)">수정</button>
                      </div>
                    </div>
                  </div>
                  <div class="btnbox" v-if="irow.depth < 5">
                    <!-- <button v-if="irow.is_writer=='N'" class="btn-add" @click="cmt.showAddCmt(i)">댓글달기</button> -->
                    <button v-if="irow.is_writer == 'Y'" class="btn-edit" @click="cmt.showModCmt(i)">수정</button>
                    <button v-if="irow.is_writer == 'Y'" class="btn-del" @click="cmt.delCmt(i)">삭제</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import {useStore} from "vuex";
import Swal from "sweetalert2";

export default {
  layout: "customerManagement",
  components: {},
  setup() {
    const router = new useRouter();
    const route = new useRoute();
    const toast = useToast();
    const store  = useStore();

    const view = reactive({
      is_loaded: false,

      idx: 0,
      is_del : false,
      info: {},

      goBizCardAdd: () => {
        store.commit("setBizCardInfo", view.info);
        window.open(`/customerManagement/businessCardAdd`, '_blank');
      },

      goScheduleOutsideWorkAdd: (idx) => {
        store.commit("setScheduleOutsideWork", view.info);
        window.open(`/customerManagement/scheduleOutsideWorkAdd/${idx}`, '_blank');
      },

      goBizCardView: (idx) => {
        window.open(`/customerManagement/businessCardView/${idx}`, '_blank');
      },

      goCustomerDBView : (idx) => {
          window.open(`/customerManagement/customerDBViewTab1/${idx}`, '_blank');
      },

      doCancel: () => {
        router.push({
          name: "customerManagement-statusSMPList",
        });
      },

      doDelete: () => {
        Swal.fire({
          title: "전략마케팅 삭제",
          text: "삭제하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            let params = {
              idx: view.idx,
            };
            axios.post("/rest/customermgr/delSMP", params).then((res) => {
              if (res.data.err == 0) {
                router.push({
                  name: "customerManagement-statusSMPList",
                });
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          }
        });
      },

      doUpdate: (tp) => {
        let val = "";
        switch (tp) {
          case "state":
            val = view.info.state;
            break;
          case "office":
            val = view.info.idx_office;
            break;
          case "team":
            val = view.info.idx_team;
            break;
        }

        let params = {
          idx: view.idx,
          key: tp,
          val: val,
        };

        axios.post("/rest/customermgr/updateSMP", params).then((res) => {
          if (res.data.err == 0) {
            toast.success("적용되었습니다.");
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      doSearch: () => {
        let params = {
          idx: view.idx,
        };

        axios.get("/rest/customermgr/getSMPInfo", { params: params }).then((res) => {
          if (res.data.err == 0) {
            view.info = res.data;

            cmt.getList();
            view.is_loaded = true;

            if(store.state.issmp) view.is_del = true;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });

    const cmt = reactive({
      page: 1,
      newtext: "",
      modtext: "",
      list: [],
      total: 0,

      getList: () => {
        let params = {
          idx: view.idx,
          page: cmt.page,
        };
        axios.get("/rest/customermgr/smpCmtList", { params: params }).then((res) => {
          if (res.data.err == 0) {
            cmt.list = res.data.list;
            cmt.total = res.data.total;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      bindDetph: (d) => {
        return "d-" + d;
      },

      addCmt: (idx_parent) => {
        let params = {
          idx: view.idx,
          comment: idx_parent > 0 ? cmt.modtext : cmt.newtext,
          idx_parent: idx_parent,
        };
        axios.post("/rest/customermgr/smpCmtAdd", params).then((res) => {
          if (res.data.err == 0) {
            cmt.newtext = "";
            view.info.cnt_comment = res.data.cnt_comment;
            cmt.getList();
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      showAddCmt: (index) => {
        for (let i = 0; i < cmt.list.length; i++) {
          cmt.list[i].is_mode = "";
        }
        cmt.modtext = "";
        cmt.list[index].is_mode = "A";
      },

      modCmt: (idx) => {
        let params = {
          idx: idx,
          comment: cmt.modtext,
        };
        axios.post("/rest/customermgr/smpCmtMod", params).then((res) => {
          if (res.data.err == 0) {
            cmt.modtext = "";
            cmt.getList();
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      showModCmt: (i) => {
        for (let i = 0; i < cmt.list.length; i++) {
          cmt.list[i].is_mode = "";
        }
        cmt.list[i].is_mode = "M";
        cmt.modtext = cmt.list[i].content;
      },

      delCmt: (i) => {
        let params = {
          idx: cmt.list[i].idx,
        };
        axios.post("/rest/customermgr/smpCmtDel", params).then((res) => {
          if (res.data.err == 0) {
            view.info.cnt_comment = res.data.cnt_comment;
            cmt.getList();
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });

    onMounted(() => {
      // Mounted
      view.idx = route.params.idx ? route.params.idx : 0;

      if (!view.idx) {
        router.back(-1);
      }

      view.doSearch();
    });

    return { view, cmt };
  },
};
</script>

<style lang="scss" scoped>
.th-wrap{
  margin: 0 auto;
}
</style>
